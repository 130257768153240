import { styled } from 'src/gatsby-theme-stitches/config'

export const WhichPhoneNumberContainer = styled('section', {
  width: '100%',
  padding: '3.75rem 0 0',
  background: '#65489C',
})

export const WhichPhoneNumberWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  width: '90%',
  margin: '0 auto',

  '@sm': {
    flexDirection: 'column',
  },
})

export const WhichPhoneNumberText = styled('div', {
  width: '62%',

  '@sm': {
    order: 2,
    width: '100%',
  },

  h3: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.6875rem',
    lineHeight: '3rem',
    color: '#FF0047',

    '@sm': {
      fontSize: '2.125rem',
      lineHeight: '3rem',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontSize: '2.75rem',
      fontWeight: '500',
      color: '#FFF',

      '@sm': {
        fontSize: '2.425rem',
        lineHeight: '2.925rem',
      },
    },
  },

  h4: {
    margin: '0.875rem 0',
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '3.6875rem',
    lineHeight: '4rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.5625rem',
      lineHeight: '1.5625rem',
    },
  },

  a: {
    textDecoration: 'none',
    color: '#FFF',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  p: {
    marginBottom: '0.75rem',
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const WhichPhoneNumberImage = styled('div', {
  flex: 1,

  '@sm': {
    order: 1,
  },

  img: {
    width: '100%',
  },
})
