import React from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import Seo from 'src/views/angela/Seo'
import AngelaView from 'src/views/angela'

import type { AssistenteVirtualAngelaPageQueryQuery } from './__generated__/AssistenteVirtualAngelaPageQuery.graphql'

export type Props = GatsbyPageProps<AssistenteVirtualAngelaPageQueryQuery>

const Page: FC<Props> = (props) => (
  <Layout>
    <Seo {...props} />
    <AngelaView {...props} />
  </Layout>
)

export const query = graphql`
  query AssistenteVirtualAngelaPageQuery {
    cmsInstitutionalPage(name: { eq: "Instituto Avon Angela" }) {
      sections {
        data
        name
      }
      seo {
        siteMetadataWithSlug {
          description
          slug
          title
          titleTemplate
        }
      }
    }
    vtex {
      facets {
        breadcrumb {
          href
          name
        }
      }
    }
  }
`

export default Page
