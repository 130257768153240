import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import EventPageView from 'src/components/common/EventPageView'
import Presentation from 'src/components/angela/Presentation'
import WhichPhoneNumber from 'src/components/angela/WhichPhoneNumber'
import OfferSupport from 'src/components/angela/OfferSupport'
import DisclosedInformations from 'src/components/angela/DisclosedInformations'
import PersonalizedService from 'src/components/angela/PersonalizedService'
import InstituteIcon from 'src/components/angela/InstituteIcon'
import FloatingWhatsApp from 'src/components/angela/FloatingWhatsApp'
import type { AssistenteVirtualAngelaPageQueryQuery } from 'src/pages/angela/__generated__/AssistenteVirtualAngelaPageQuery.graphql'

type Props = PageProps<AssistenteVirtualAngelaPageQueryQuery>

const AngelaView: FC<Props> = ({ data: { cmsInstitutionalPage } }) => {
  if (!cmsInstitutionalPage) {
    return null
  }

  return (
    <div>
      <EventPageView
        type="OTHER"
        title={cmsInstitutionalPage?.seo?.siteMetadataWithSlug?.title}
      />

      <Presentation data={cmsInstitutionalPage} />
      <WhichPhoneNumber data={cmsInstitutionalPage} />
      <OfferSupport data={cmsInstitutionalPage} />
      <DisclosedInformations data={cmsInstitutionalPage} />
      <PersonalizedService data={cmsInstitutionalPage} />
      <InstituteIcon />
      <FloatingWhatsApp />
    </div>
  )
}

export default AngelaView
