import { styled } from 'src/gatsby-theme-stitches/config'

export const PersonalizedServiceContainer = styled('section', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.875rem',
  width: '100%',
  paddingLeft: '3.125rem',
  background: '#65489C',

  '@sm': {
    flexDirection: 'column',
    gap: '0',
    paddingLeft: '0',
  },
})

export const PersonalizedServiceText = styled('div', {
  width: '66%',

  '@sm': {
    position: 'relative',
    order: '2',
    width: '90%',
    zIndex: 1,
  },

  h3: {
    padding: '0.625rem',
    background: '#FF0047',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2rem',
    fontWeight: '500',
    lineHeight: '2rem',
    color: '#FFF',

    '@sm': {
      padding: '0 1.25rem',
      fontSize: '1.5625rem',
      lineHeight: '2.4375rem',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontSize: '1.875rem',

      '@sm': {
        fontSize: '1.5625rem',
        lineHeight: '2.4375rem',
      },
    },
  },

  p: {
    marginTop: '1rem',
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const PersonalizedServiceImage = styled('div', {
  position: 'relative',
  flex: 1,

  '@sm': {
    top: '1rem',
    order: '1',
  },

  img: {
    width: '100%',
  },
})
