import React from 'react'
import { WhatsappSvg } from 'src/components/icons'
import { Link } from '@vtex/store-ui'

import * as S from './styles'

const FloatingWhatsApp = () => {
  return (
    <Link
      href="https://api.whatsapp.com/send?phone=5511944942415"
      target="_blank"
      rel="noreferrer"
    >
      <S.FloatingWhatsAppContainer>
        <WhatsappSvg />
      </S.FloatingWhatsAppContainer>
    </Link>
  )
}

export default FloatingWhatsApp
