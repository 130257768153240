import React from 'react'
import type { FC } from 'react'
import { Image } from '@vtex/store-ui'
import RichText from 'src/components/ui/RichText'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const PersonalizedService: FC<Props> = ({ data }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <S.PersonalizedServiceContainer>
      <S.PersonalizedServiceText>
        <RichText richText={data.sections[16].data.content} />
      </S.PersonalizedServiceText>
      <S.PersonalizedServiceImage>
        <Image
          src={
            isMobile
              ? data.sections[18].data.mobile.srcSet
              : data.sections[18].data.desktop.srcSet
          }
          alt={data.sections[18].data.alt}
        />
      </S.PersonalizedServiceImage>
    </S.PersonalizedServiceContainer>
  )
}

export default PersonalizedService
