import React from 'react'
import { Link } from '@vtex/store-ui'
import { AvonInstituteIcon } from 'src/components/icons'

import * as S from './styles'

const InstituteIcon = () => {
  return (
    <S.InstituteContainer>
      <Link href="https://institutoavon.org.br" target="_blank" rel="noopener">
        <AvonInstituteIcon />
      </Link>
    </S.InstituteContainer>
  )
}

export default InstituteIcon
