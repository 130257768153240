import React from 'react'
import type { FC } from 'react'
import { Image, Link } from '@vtex/store-ui'
import RichText from 'src/components/ui/RichText'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const WhichNumberPhone: FC<Props> = ({ data }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <S.WhichPhoneNumberContainer>
      <S.WhichPhoneNumberWrapper>
        <S.WhichPhoneNumberText>
          <RichText richText={data.sections[5].data.content} />
          <Link
            href="https://api.whatsapp.com/send?phone=5511944942415"
            target="_blank"
            rel="noreferrer"
          >
            <RichText richText={data.sections[6].data.content} />
          </Link>
          <RichText richText={data.sections[7].data.content} />
        </S.WhichPhoneNumberText>
        <S.WhichPhoneNumberImage>
          <Image
            src={
              isMobile
                ? data.sections[8].data.mobile.srcSet
                : data.sections[8].data.desktop.srcSet
            }
            alt={data.sections[8].data.alt}
          />
        </S.WhichPhoneNumberImage>
      </S.WhichPhoneNumberWrapper>
    </S.WhichPhoneNumberContainer>
  )
}

export default WhichNumberPhone
