import React, { Fragment } from 'react'
import type { FC } from 'react'
import { Image } from '@vtex/store-ui'
import RichText from 'src/components/ui/RichText'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const OfferSupport: FC<Props> = ({ data }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <Fragment>
      <S.OfferSupportContainer>
        <S.OfferSupportImage>
          <Image
            src={
              isMobile
                ? data.sections[9].data.mobile.srcSet
                : data.sections[9].data.desktop.srcSet
            }
            alt={data.sections[9].data.alt}
          />
        </S.OfferSupportImage>
        <S.OfferSupportText>
          <RichText richText={data.sections[10].data.content} />
        </S.OfferSupportText>
        <S.OfferSupportDecalque>
          {!isMobile && (
            <Image
              src={data.sections[11].data.desktop.srcSet}
              alt={data.sections[11].data.alt}
            />
          )}
        </S.OfferSupportDecalque>
      </S.OfferSupportContainer>
      <S.OfferSupportDescriptionContainer>
        <S.OfferSupportDescriptionWrapper>
          <S.OfferSupportDescriptionText>
            <RichText richText={data.sections[12].data.content} />
          </S.OfferSupportDescriptionText>
          <S.OfferSupportDescriptionImage>
            <Image
              src={
                isMobile
                  ? data.sections[13].data.mobile.srcSet
                  : data.sections[13].data.desktop.srcSet
              }
              alt={data.sections[13].data.alt}
            />
          </S.OfferSupportDescriptionImage>
        </S.OfferSupportDescriptionWrapper>
      </S.OfferSupportDescriptionContainer>
    </Fragment>
  )
}

export default OfferSupport
