import React, { Fragment } from 'react'
import type { FC } from 'react'
import { Image } from '@vtex/store-ui'
import RichText from 'src/components/ui/RichText'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const Presentation: FC<Props> = ({ data }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <Fragment>
      <S.PresentationContainer>
        <S.FirstFlowerDecalque>
          <Image
            src={
              isMobile
                ? data.sections[0].data.mobile.srcSet
                : data.sections[0].data.desktop.srcSet
            }
            alt={data.sections[0].data.alt}
          />
        </S.FirstFlowerDecalque>
        <S.Title>
          <RichText richText={data.sections[1].data.content} />
        </S.Title>
        {!isMobile && (
          <S.SecondFlowerDecalque>
            <Image
              src={data.sections[2].data.desktop.srcSet}
              alt={data.sections[2].data.alt}
            />
          </S.SecondFlowerDecalque>
        )}
      </S.PresentationContainer>
      <S.WhoIsAngelaContainer>
        <S.WhoIsAngelaWrapper>
          <S.WhoIsAngelaImage>
            <Image
              src={
                isMobile
                  ? data.sections[3].data.mobile.srcSet
                  : data.sections[3].data.desktop.srcSet
              }
              alt={data.sections[3].data.alt}
            />
          </S.WhoIsAngelaImage>
          <S.WhoIsAngelaText>
            <RichText richText={data.sections[4].data.content} />
          </S.WhoIsAngelaText>
        </S.WhoIsAngelaWrapper>
      </S.WhoIsAngelaContainer>
    </Fragment>
  )
}

export default Presentation
