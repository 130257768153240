import { styled } from 'src/gatsby-theme-stitches/config'

export const DisclosedInformationsContainer = styled('section', {
  position: 'relative',
  width: '100%',
  padding: '4rem 0 8rem',
  background: '#65489C',

  '@sm': {
    padding: '0',
  },
})

export const DisclosedInformationsWrapper = styled('div', {
  width: '100%',
  display: 'flex',

  '@sm': {
    flexDirection: 'column',
  },
})

export const DisclosedInformationsFirstImage = styled('div', {
  '@lg': {
    position: 'absolute',
    top: '9.375rem',
    left: '0',
  },

  img: {
    position: 'relative',
  },
})

export const DisclosedInformationsText = styled('div', {
  margin: '0 auto',
  width: '75%',
  padding: '3.125rem 2.5rem',
  background: '#B8A0CC',

  '@sm': {
    width: '100%',
    padding: '3.125rem 0.5rem',
  },

  '@lg': {
    position: 'relative',
    zIndex: 1,
  },

  h3: {
    marginBottom: '1.25rem',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.5rem',
    fontWeight: '500',
    textAlign: 'center',
    color: '#65013B',

    '@sm': {
      marginBottom: '2rem',
      fontSize: '2.125rem',
      lineHeight: '3rem',
    },

    strong: {
      padding: '0.625rem',
      background: '#FF0047',
      fontWeight: '500',
      color: '#F89C21',
    },
  },

  p: {
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    textAlign: 'center',
    color: '#65013B',

    '@sm': {
      fontSize: '1.25rem',
      textAlign: 'initial',
      lineHeight: '1.5rem',
    },

    '@lg': {
      padding: '0 5.625rem',
    },

    strong: {
      padding: '0.2rem 0.625rem',
      background: '#FF0047',
      color: '#FFF',
    },
  },
})

export const DisclosedInformationsSecondImage = styled('div', {
  '@lg': {
    position: 'absolute',
    top: '0.625rem',
    right: '0',
  },

  img: {
    position: 'relative',
  },
})
