import { styled } from 'src/gatsby-theme-stitches/config'

export const PresentationContainer = styled('section', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  background: '#65489C',
  overflow: 'hidden',

  '@lg': {
    paddingBottom: '6.25rem',
  },
})

export const FirstFlowerDecalque = styled('div', {
  '@lg': {
    width: '30%',
  },

  '@sm': {
    position: 'absolute',
    top: '0',
    left: '0',
  },

  img: {
    width: '100%',
  },
})

export const Title = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '42%',

  '@sm': {
    margin: '5.5rem auto 1.25rem',
    width: '100%',
  },

  '@lg': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  h1: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '5.375rem',
    fontWeight: '700',
    lineHeight: '7.375rem',
    textAlign: 'center',
    color: '#FF903D',

    '@sm': {
      fontSize: '2.9375rem',
      lineHeight: '4rem',
    },
  },

  h2: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '3.3125rem',
    fontWeight: '500',
    lineHeight: '4rem',
    textAlign: 'center',
    color: '#FFF',

    '@sm': {
      fontSize: '1.8125rem',
      lineHeight: '2.0625rem',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontSize: '4.625rem',
      fontWeight: '600',
      lineHeight: '4.625rem',
      textAlign: 'center',
      color: '#FFF',

      '@sm': {
        fontSize: '2.5625rem',
        lineHeight: '3rem',
      },
    },
  },
})

export const SecondFlowerDecalque = styled('div', {
  '@lg': {
    position: 'relative',
    top: '6.625rem',
    width: '25%',
  },

  img: {
    width: '100%',
  },
})

export const WhoIsAngelaContainer = styled('section', {
  width: '100%',
  background: '#65489C',
})

export const WhoIsAngelaWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.875rem',
  width: '95%',
  padding: '0 50px 0 70px',
  background: '#FF0047',

  '@sm': {
    flexDirection: 'column',
    gap: '0',
    width: '100%',
    padding: '0',
    background: 'initial',
  },

  '@lg': {
    height: '34.2rem',
  },

  '@media screen and (min-width: 1420px)': {
    height: 'initial',
  },
})

export const WhoIsAngelaImage = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flex: '1',

  '@sm': {
    justifyContent: 'end',
    width: '100%',
  },

  '@lg': {
    position: 'relative',
    bottom: '3.5rem',
  },

  '@media screen and (min-width: 1420px)': {
    bottom: 'initial',
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',

    '@sm': {
      width: 'initial',
    },

    '@media screen and (min-width: 1420px)': {
      width: 'initial',
      height: 'initial',
      objectFit: 'initial',
    },
  },
})

export const WhoIsAngelaText = styled('div', {
  width: '60%',
  background: '#FF0047',

  '@sm': {
    width: '100%',
    background: '#FF0047',
    padding: '1.875rem 1.5rem',
  },

  h4: {
    marginBottom: '1.375rem',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.6875rem',
    fontWeight: '500',
    lineHeight: '3rem',
    color: '#FF903D',

    '@sm': {
      fontSize: '2.125rem',
      lineHeight: '2.125rem',
      textAlign: 'center',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontWeight: '700',
      color: '#FFF',
    },
  },

  p: {
    marginBottom: '1.2rem',
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.6875rem',
    color: '#FFF',

    '@sm': {
      marginBottom: '2rem',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})
