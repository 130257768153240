import React from 'react'
import type { FC } from 'react'
import { Image } from '@vtex/store-ui'
import RichText from 'src/components/ui/RichText'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import * as S from './styles'

interface Props {
  data: {
    sections: Array<{
      name: string
      data: {
        alt: string
        desktop: { srcSet: string }
        mobile: { srcSet: string }
        content: string
      }
    }>
  }
}

const DisclosedInformations: FC<Props> = ({ data }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <S.DisclosedInformationsContainer>
      <S.DisclosedInformationsWrapper>
        <S.DisclosedInformationsFirstImage>
          {!isMobile && (
            <Image
              src={data.sections[14].data.desktop.srcSet}
              alt={data.sections[14].data.alt}
            />
          )}
        </S.DisclosedInformationsFirstImage>
        <S.DisclosedInformationsText>
          <RichText richText={data.sections[15].data.content} />
        </S.DisclosedInformationsText>
        <S.DisclosedInformationsSecondImage>
          {!isMobile && (
            <Image
              src={data.sections[17].data.desktop.srcSet}
              alt={data.sections[17].data.alt}
            />
          )}
        </S.DisclosedInformationsSecondImage>
      </S.DisclosedInformationsWrapper>
    </S.DisclosedInformationsContainer>
  )
}

export default DisclosedInformations
