import { styled } from 'src/gatsby-theme-stitches/config'

export const OfferSupportContainer = styled('section', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '2rem',
  width: '100%',
  background: '#65489C',

  '@sm': {
    flexDirection: 'column',
  },
})

export const OfferSupportImage = styled('div', {
  width: '30%',

  '@sm': {
    width: 'initial',
  },

  img: {
    width: '100%',
  },
})

export const OfferSupportText = styled('div', {
  flex: 1,

  '@sm': {
    width: '85%',
  },

  h3: {
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.8125rem',
    fontWeight: '500',
    lineHeight: '3.625rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.875rem',
      lineHeight: '2.275rem',
    },
  },

  h4: {
    marginBottom: '1.25rem',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.4375rem',
    lineHeight: '3.1875rem',
    color: '#FF0047',

    '@sm': {
      fontSize: '1.875rem',
      lineHeight: '2.275rem',
    },
  },

  p: {
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const OfferSupportDecalque = styled('div', {
  boxSizing: 'border-box',

  '@sm': {
    display: 'none',
  },
})

export const OfferSupportDescriptionContainer = styled('div', {
  width: '100%',
  background: '#65489C',
})

export const OfferSupportDescriptionWrapper = styled('div', {
  margin: '0 auto',
  width: '90%',
  display: 'flex',
  alignItems: 'center',

  '@sm': {
    flexDirection: 'column',
  },
})

export const OfferSupportDescriptionText = styled('div', {
  width: '68%',

  '@sm': {
    order: '2',
    width: '100%',
    padding: '0 0 2rem',
  },

  h3: {
    marginBottom: '0.75rem',
    fontFamily: 'Tusker Grotesk',
    fontSize: '2.5rem',
    fontWeight: '500',
    color: '#FFF',

    '@sm': {
      fontSize: '2.125rem',
      lineHeight: '2.125rem',
    },

    strong: {
      fontFamily: 'Tusker Grotesk',
      fontSize: '2.6875rem',
      lineHeight: '2.6875rem',
      color: '#FF0047',

      '@sm': {
        fontSize: '2.125rem',
        lineHeight: '2.925rem',
      },
    },
  },

  p: {
    fontFamily: 'Lato',
    fontSize: '1.4375rem',
    lineHeight: '1.75rem',
    color: '#FFF',

    '@sm': {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
})

export const OfferSupportDescriptionImage = styled('div', {
  '@sm': {
    order: '1',
    marginBottom: '1.5rem',
  },

  img: {
    width: '100%',
  },
})
