import { styled } from 'src/gatsby-theme-stitches/config'

export const FloatingWhatsAppContainer = styled('button', {
  position: 'fixed',
  bottom: '8.5rem',
  right: '0.3125rem',
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  background: '#25D366',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  zIndex: 1,

  svg: {
    width: '58%',
  },
})
